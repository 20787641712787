<template>
  <!-- 新增充值页面 -->
  <div class="makeOutInvoice page-info-content">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :inline="true"
      label-width="130px"
      class="demo-ruleForm page-form form-item-row"
    >
      <h3 class="page-subtitle-shade">
        填写开票信息
      </h3>
      <el-form-item label="发票接收方式：" prop="settleBankId">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio label="1">
            电子
          </el-radio>
          <el-radio label="2">
            纸质
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发票类型：" prop="settleBankId">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio label="1">
            普票
          </el-radio>
          <el-radio label="2">
            专票
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发票代码：" prop="operPlat">
        <el-input v-model="ruleForm.remark" placeholder="请输入发票代码" />
      </el-form-item>
      <el-form-item label="发票号码：" prop="operPlat">
        <el-input v-model="ruleForm.remark" placeholder="请输入发票号码" />
      </el-form-item>
      <el-form-item label="上传发票：" prop="operPlat">
        <el-input v-model="ruleForm.remark" placeholder="请输入发票号码" />
      </el-form-item>
      <h3 class="page-subtitle-shade">
        发票详情
      </h3>
      <el-form-item label="发票抬头：">
        {{ ruleForm.invoiceName }}
      </el-form-item>
      <el-form-item label="纳税人识别号：">
        {{ ruleForm.invoiceUnicode }}
      </el-form-item>
      <el-form-item label="开户行名称：">
        {{ ruleForm.invoiceBankName }}
      </el-form-item>
      <el-form-item label="开户行账号：">
        {{ ruleForm.invoiceBankCode }}
      </el-form-item>
      <el-form-item label="公司联系电话：">
        {{ ruleForm.invoiceTel }}
      </el-form-item>
      <el-form-item label="公司地址：">
        {{ ruleForm.recAddr }}
      </el-form-item>
      <el-form-item label="发票内容：">
        平台服务费
      </el-form-item>
      <el-form-item label="开票金额：">
        {{ ruleForm.operPlat }}
      </el-form-item>
      <h3 class="page-subtitle-shade">
        接收信息
      </h3>
      <el-form-item label="电子邮箱：">
        {{ ruleForm.recMail }}
      </el-form-item>
      <el-form-item label="联系人姓名：">
        {{ ruleForm.recName }}
      </el-form-item>
      <el-form-item label="联系人电话：">
        {{ ruleForm.recTel }}
      </el-form-item>
      <el-form-item class="lastOne" label="备注：">
        {{ ruleForm.remark }}
      </el-form-item>
      <h3 class="page-subtitle-shade">
        代采申请单号
      </h3>
      <el-form-item label="申请单号：">
        {{ ruleForm.operPlat }}
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button type="primary" size="small" class="widen-button" @click="submitForm('ruleForm')">
        确定
      </el-button>
      <el-button size="small" class="widen-button" @click="resetForm('ruleForm')">
        重置
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {},
      cmpInvoiceArr: [],
      rules: {
        cmpId: [
          { required: true, message: '请输入选择一个会员', trigger: 'change' }
        ],
        settleBankId: [
          { required: true, message: '请选择结算银行', trigger: 'change' }
        ],
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' }
        ],
        operPlat: [
          { required: true, message: '请选择渠道', trigger: 'change' }
        ],
        payWay: [
          { required: true, message: '请选择支付方式', trigger: 'change' }
        ],
        payTime: [
          { required: true, message: '请选择充值时间', trigger: 'change' }
        ]
      },
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/salesContractManagement/index', name: '销售合同管理' }, { path: '', name: '开票提交' }]
    }
  },
  methods: {
    // 发票抬头选择
    getInformation() {
      // 取到相对应的值
      this.cmpInvoiceArr.forEach(item => {
        if (item.id === this.ruleForm.operPlat) {
          Object.assign(this.ruleForm, item)
        }
      })
    },
    // 点击确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // if (valid) {

        // }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }

}
</script>

<style lang="scss">
.makeOutInvoice {
  .el-form {
    padding: 32px 0;
    // .fromTitle{
    //   font-size: 16px;
    //   color: #333;
    //   font-weight: 700;
    //   padding-left: 12px;
    //   height: 16px;
    //   line-height: 16px;
    //   margin-bottom: 12px;
    //   border-left: 3px solid red;
    // }
  }
  .buttonCombination {
    text-align: center;
  }
  // .el-form .el-form-item {
  //   width: 32%;
  // }
  // .el-form .el-form-item__content {
  //   width: calc(100% - 130px);
  // }
  .el-form .lastOne {
    width: calc(96% + 20px);
  }
  .el-form .el-input__inner,
  .el-form .el-select,
  .el-form .el-input,
  .el-form .el-cascader {
    width: 100%;
  }

}
</style>
